/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useRef, useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Icon from "@mui/material/Icon";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 PRO MUI base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";

import ReactEcharts from "echarts-for-react";

function ProductivityChart({ name, points, comparingBuild, selectedBuild }) {
    const tag = useRef();

    const { white, info, primary, secondary, dark } = colors;
    const { size } = typography;
    const chartRef = useRef(null);
    const [openMenu, setOpenMenu] = useState(null);
    const [options, setOptions] = useState(null);

    useEffect(() => {
        setOptions({
            legend: {
                top: 'top',
                left: 'center',
                orient: 'horizontal',
                data: selectedBuild == "Build undefined" ? [comparingBuild] : [comparingBuild, selectedBuild]
            },
            xAxis: {
                data: points?.selected ?? [],
                show: false
            },
            yAxis: {
                show: false
            },
            grid: [
                {
                    show: false,
                    left: 0,
                    top: '10%',
                    bottom: 0,
                    right: 0
                }
            ],
            tooltip: {
                show: true,
                trigger: 'item'
            },
            series: [
                {
                    name: comparingBuild,
                    data: points?.comparing?.map(v => v.toFixed(2)) ?? [],
                    showSymbol: true,
                    type: 'line',
                    color: primary.main,
                    symbolSize: 2,
                    lineStyle: {
                        color: primary.main
                    },
                    areaStyle: {
                        color: primary.main,
                        opacity: 0.5
                    },
                    smooth: true
                },
                ...(selectedBuild != "Build undefined" ? [{
                    name: selectedBuild,
                    data: points?.selected?.map(v => v.toFixed(2)) ?? [],
                    showSymbol: false,
                    type: 'line',
                    color: info.main,
                    areaStyle: {
                        color: info.main,
                        opacity: 0.5
                    },
                    smooth: true
                }] : [])
            ]
        });
    }, [points])

    const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
    const handleCloseMenu = () => setOpenMenu(null);

    const getDetails = () => {
        if (points == null) return "";

        let comparingTotal = points.comparing.reduce((a, b) => a + b, 0)
        let selectedTotal = points.selected.reduce((a, b) => a + b, 0)

        let comparingAverage = comparingTotal / points.comparing.length
        let selectedAverage = selectedTotal / points.selected.length

        let diff = comparingAverage - selectedAverage
        let absDiff = Math.abs(diff).toFixed(2)

        let keyword = ""
        let icon = "trending_flat"
        let iconColor = "primary"

        if (diff == 0) {
            return (
                <ArgonBox display="flex" alignItems="center">
                    <ArgonBox fontSize={size.lg} color={iconColor} mb={0.3} mr={0.5} lineHeight={0}>
                        <Icon sx={{ fontWeight: "bold" }}>{icon}</Icon>
                    </ArgonBox>
                    <ArgonTypography variant="button" color="dark" fontWeight="medium">
                        <ArgonTypography variant="button" color="dark" fontWeight="regular">
                            Same as
                        </ArgonTypography>
                        &nbsp;{selectedBuild}
                    </ArgonTypography>
                </ArgonBox>
            );
        }

        if (isNaN(diff)) {
            return <></>
        }

        if (diff > 0) {
            keyword = " more "
            icon = "trending_down"
            iconColor = "error"
        } else {
            keyword = " less "
            icon = "trending_up"
            iconColor = "success"
        }

        return (
            <ArgonBox display="flex" alignItems="center">
                <ArgonBox fontSize={size.lg} color={iconColor} mb={0.3} mr={0.5} lineHeight={0}>
                    <Icon sx={{ fontWeight: "bold" }}>{icon}</Icon>
                </ArgonBox>
                <ArgonTypography variant="button" color="dark" fontWeight="medium">
                    {absDiff} ms
                    <ArgonTypography variant="button" color="dark" fontWeight="regular">
                        {keyword} than
                    </ArgonTypography>
                    &nbsp;{selectedBuild}
                </ArgonTypography>
            </ArgonBox>
        );
    }

    const renderMenu = () => (
        <Menu
            anchorEl={openMenu}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={Boolean(openMenu)}
            onClose={handleCloseMenu}
            keepMounted
        >
            <MenuItem onClick={handleCloseMenu}>Action</MenuItem>
            <MenuItem onClick={handleCloseMenu}>Anoter action</MenuItem>
            <MenuItem onClick={handleCloseMenu}>Something else here</MenuItem>
        </Menu>
    );

    return (
        <Card>
            <ArgonBox p={2}>
                <ArgonBox display="flex" justifyContent="space-between">
                    <ArgonBox>
                        <ArgonTypography variant="h6" fontWeight="medium" color="dark">
                            {name}
                        </ArgonTypography>


                        {getDetails()}

                    </ArgonBox>
                    <ArgonTypography color="white" onClick={handleOpenMenu}>
                        <Icon fontSize="default" sx={{ cursor: "pointer" }}>
                            more_horiz
                        </Icon>
                    </ArgonTypography>
                    {renderMenu()}
                </ArgonBox>
            </ArgonBox>
            {options != null &&
                <div ref={chartRef}>
                    <ReactEcharts option={options} />
                </div>}
        </Card>
    );
}

export default ProductivityChart;
