

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import ArgonSelect from "../../../components/ArgonSelect";

// @mui material components
import Card from "@mui/material/Card";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 PRO MUI context
import { CardContent, IconButton } from "@mui/material";
import Grid from "@mui/material/Grid";

import { Delete } from "@mui/icons-material";
import colors from "assets/theme/base/colors";
import { PerfChartsAPI } from "api/BackendApi/PerfChartsAPI";

const { primary, error } = colors;

function ControllerCardPerformanceCharts({ configId, icon, title, description, onRemove, onWidthChange, chartWidth, deletable }) {

  var cardStyle = { outline: 1, outlineColor: "primary.main" };
  var cardContentStyle = { backgroundColor: primary.main + "09" };

  const remove = async () => {
    await PerfChartsAPI.remove(configId, "API");
    onRemove();
  }

  return (
    <Card sx={{ ...cardStyle, height: "100%", width: "14vw" }}>

      <CardContent sx={cardContentStyle}>
        <ArgonBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          lineHeight={1}
        >
          <ArgonBox mt={1} lineHeight={1}>
            <ArgonTypography
              variant="body2"
              textTransform="capitalize"
              fontWeight="medium">
              {title}
            </ArgonTypography>
          </ArgonBox>
          {window.user.canEditChart() && deletable && <IconButton mr={1} onClick={remove}>
            <Delete color={error.main} />
          </IconButton>}
        </ArgonBox>
        <ArgonBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={1}
          lineHeight={1}
        >
          <ArgonTypography variant="body2">
            Width
          </ArgonTypography>

          {window.user.canEditChart() && <Grid item lg={4}>
            <ArgonSelect
              size={"small"}
              sx={{ overflow: "hidden" }}
              placeholder={"Width"}
              onChange={onWidthChange}
              menuPlacement="auto"
              menuPosition="fixed"
              menuPortalTarget={document.body}
              options={[{ "value": 1, "label": "1" }, { "value": 2, "label": "2" }, { "value": 3, "label": "3" }, { "value": 4, "label": "4" }]}
              defaultValue={{ "value": chartWidth, "label": "" + chartWidth }}
            />
          </Grid>}

        </ArgonBox>

        <ArgonBox mt={1} />
        <ArgonBox >
          {icon}
        </ArgonBox>
      </CardContent>

    </Card>
  );
}

// Setting default values for the props of ControllerCardCharts
ControllerCardPerformanceCharts.defaultProps = {
  color: "info",
  description: "",
};

// Typechecking props for the ControllerCardCharts
ControllerCardPerformanceCharts.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  chartWidth: PropTypes.number,
};

export default ControllerCardPerformanceCharts;
