import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import useAuth from "../../utils/useAuth";

// @mui material components
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, CardActionArea, CardHeader, CircularProgress, Divider, Stack } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { styled } from '@mui/material/styles';

// Argon Dashboard 2 PRO MUI example components
import { PerfChartsAPI } from "api/BackendApi/PerfChartsAPI";
import ArgonBox from "../../components/ArgonBox";
import ArgonButton from "../../components/ArgonButton";

// Overview page components
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import DashboardLayout from "../../components/LayoutContainers/DashboardLayout";
import BoxCapabilities from "../dashboard/components/BoxCapabilities";

import { Module } from "../../api/BackendApi/Module";
import { TestConfig } from "../../api/BackendApi/TestConfiguration";
import ArgonTypography from "../../components/ArgonTypography";

import { Chart, registerables } from 'chart.js';
import ArgonBadge from "components/ArgonBadge";
import CompactControllerCard from "components/Cards/CompactControllerCard";
import { enqueueSnackbar } from "notistack";
import PerformanceSelectFilters from "./components/PerformanceSelectFilters";
import PerfBuildsTable from "./components/PerfBuildsTable";
import PerfRunsTable from "./components/PerfRunsTable";
import ProductivityChart from "./components/ProductivityChart";
import ResponseCodePerSecond from "./components/ResponseCodePerSecond";
import ResponseTimePctOverTime from "./components/ResponseTimePctOverTime";
import ResponseTimeVsRequest from "./components/ResponseTimeVsRequest";

import colors from "assets/theme/base/colors";
import ArgonSelect from "components/ArgonSelect";
import PerfUIStepsTable from "./components/PerfUIStepsTable";


Chart.register(...registerables);

const loading = () => {
  return (<Stack
    direction="row"
    justifyContent="center"
    alignItems="center"
    sx={{ width: 1, height: '400px' }}
  >
    <CircularProgress size={64} />
  </Stack>);
};

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const { white, info, primary, secondary, dark } = colors;

function PerformancePage() {

  const isLoggedIn = useAuth().ensureLoggedIn();

  if (!isLoggedIn) {
    return <Navigate replace to="/sign-in" />;
  }

  const [expanded, setExpanded] = useState(true);
  const [testInstanceTableExpanded, setTestInstanceTableExpanded] = useState(false);
  const [moduleId, setModuleId] = useState(null);
  const [configs, setConfigs] = useState(null);
  const [builds, setBuilds] = useState(null);

  const [chartsConfigs, setChartsConfigs] = useState(null);

  const [selectedRowData, setSelectedRowData] = useState(null);
  const [selectedCapability, setSelectedCapablity] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState(null);

  const [appliedComparingBuild, setAppliedComparingBuild] = useState(null);
  const [appliedFilters, setAppliedFilters] = useState(null);
  const [appliedCapability, setAppliedCapability] = useState(null);
  const [applyEnabled, setApplyEnabled] = useState(false);

  const [selectedBuild, setSelectedBuild] = useState(null);

  const [availableLabels, setAvailableLabels] = useState(null);
  const [selectedLabel, setSelectedLabel] = useState(null);

  const [chartData, setChartData] = useState(null);

  const availableOptionsMappings = {
    "Mean": "meanResTime",
    "Median": "medianResTim",
    "Max": "maxResTime",
    "PCT 90": "pct1ResTime",
    "PCT 95": "pct2ResTime",
    "PCT 99": "pct3ResTime",
    "Throughput": "throughput",
    "Error%": "errorPct"
  };

  const availableOptions = Object.entries(availableOptionsMappings).map(o => { return { "value": o[1], "label": o[0] } })

  const applyFilters = async (filters) => {
    let response2 = await PerfChartsAPI.getBuilds(selectedCapability);
    setBuilds(response2.data.builds);

    setAppliedComparingBuild(response2.data.builds[0].name)

    setAppliedFilters(filters);
    setAppliedCapability(selectedCapability);
    // setAppliedComparingBuild(selectedComparingBuild);
    setExpanded(false);
    setTestInstanceTableExpanded(true);

    setChartData(null)
    setSelectedRowData(null)
    setAvailableLabels(null)
    setSelectedLabel(null)
  }

  useEffect(() => {
    setApplyEnabled(selectedCapability != null);
  }, [selectedCapability])


  const handleInstanceSelection = (row) => {
    let availableBuilds = getBuildsWithoutTarget(row);
    if (availableBuilds.includes("Baseline")) {
      setSelectedBuild("Baseline")
    } else {
      setSelectedBuild(availableBuilds[0])
    }

    setSelectedRowData(row)
    setTestInstanceTableExpanded(false);
  }

  const loadCharts = async () => {
    if (appliedComparingBuild == selectedBuild) return;
    
    let selection = { "filters": appliedFilters ?? {}, "capability": appliedCapability, "comparingBuild": appliedComparingBuild, "selectedBuild": selectedBuild, "instance": selectedRowData.instance._id };
    console.log(selection)
    await loadChartConfigs();
    await loadComparisonData(selection);

    setSelectedLabel("All"); // response.data.charts.over_time.labels[0])
  }

  useEffect(() => {
    setChartData(null)
    if (selectedRowData == null) return;

    loadCharts();

  }, [selectedBuild, selectedRowData, appliedComparingBuild]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleTestInstancesExpandClick = () => {
    setTestInstanceTableExpanded(!testInstanceTableExpanded);
  };

  const loadConfigs = async () => {
    let configsResponse = await TestConfig.getConfigs();
    let mappedConfigs = {};
    for (let config of configsResponse.data.configurations) {
      if (!(config.type in mappedConfigs)) {
        mappedConfigs[config.type] = [];
      }
      mappedConfigs[config.type].push(config);
    }

    delete mappedConfigs["Build"]

    mappedConfigs.Capability = mappedConfigs.Capability
      .filter(c => c.enabled)

    setConfigs(mappedConfigs);
  };

  const onFilterChanged = (name, value) => {
    let newFilters = { ...selectedFilters };
    if (value == null) {
      delete newFilters[name];
    } else {
      newFilters[name] = value;
    }

    setSelectedFilters(newFilters);
  };

  const isBuildSelected = (build) => {
    return selectedBuild == build;
  }

  const getFiltersHeader = () => {
    return <>
      <CardHeader title="Filter"
        action={
          <ExpandMore expand={expanded} onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more">
            <ExpandMoreIcon />
          </ExpandMore>
        }
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <PerformanceSelectFilters
            dashboardId={moduleId}
            filters={configs}
            onChange={onFilterChanged}
          />
          <BoxCapabilities capabilities={configs?.Capability} flagsMap={{ [selectedCapability]: true }} onChange={setSelectedCapablity} />
          <ArgonButton
            disabled={!applyEnabled}
            onClick={() => applyFilters(selectedFilters)}
            variant="gradient"
            color="info"
            fullWidth>
            Apply
          </ArgonButton>
        </CardContent>
      </Collapse>
    </>;
  };

  const getTableOrBreadcrumb = () => {
    if (appliedCapability == null) return <div></div>;

    var title = "Select a test..."
    if (selectedRowData != null) {
      title = selectedRowData.testName;
    }

    return <><CardHeader title={title}
      action={
        <ExpandMore expand={testInstanceTableExpanded} onClick={handleTestInstancesExpandClick} aria-expanded={testInstanceTableExpanded} aria-label="show more">
          <ExpandMoreIcon />
        </ExpandMore>
      }
    />
      <Collapse in={testInstanceTableExpanded} timeout="auto" unmountOnExit={false}>
        <CardContent>
          <PerfBuildsTable onSelectedRow={handleInstanceSelection} availableStats={availableOptions} selectedStat={availableOptions[0]} availableOptionsMappings={availableOptionsMappings} capability={appliedCapability} />
        </CardContent>
      </Collapse>
    </>
  }

  const loadComparisonData = async (selection) => {
    let response = await PerfChartsAPI.loadComparisonCharts(selection, appliedCapability)
    setChartData(response.data.charts);
    setAvailableLabels(response.data.charts?.over_time?.labels)
  }

  const loadChartConfigs = async () => {
    let response = await PerfChartsAPI.getConfigs(appliedCapability);
    setChartsConfigs(response.data.configs)
  }

  const getBuildsWithoutTarget = (rowData) => {
    if (rowData == null) return [];
    var instanceBuilds = Object.keys(rowData.data);

    let builds = []
    for (var run = 0; run < instanceBuilds.length; run++) {
      let build_name = instanceBuilds[run]
      if (build_name == appliedComparingBuild) continue;
      builds.push(build_name)
    }
    return builds;
  }

  const onComparingBuildChange = (buildName) => {
    setAppliedComparingBuild(buildName)
  }

  useEffect(() => {
    let availableBuilds = getBuildsWithoutTarget(selectedRowData);
    if (availableBuilds.includes("Baseline")) {
      setSelectedBuild("Baseline")
    } else {
      setSelectedBuild(availableBuilds[0])
      console.log(availableBuilds[0])
    }
  }, [appliedComparingBuild])

  var cardStyle = { outline: 1, outlineColor: primary.main, backgroundColor: primary.main + "09" };


  const getGraphs = () => {
    if (selectedRowData == null) return [];

    let controllerCards = [];

    let buildsWithoutTarget = getBuildsWithoutTarget(selectedRowData);

    for (let build_name of buildsWithoutTarget) {
      controllerCards.push(
        <Grid item xs={1}>
          <CompactControllerCard
            title={"Build " + build_name}
            description={(false) ? <ArgonBadge badgeContent={"#RunTag"} variant="gradient" size="xs" color="info" container /> : ""}
            state={isBuildSelected(build_name)}
            color={info.main}
            onChange={(event) => setSelectedBuild(build_name)}
            showSwitch={false}
          />
        </Grid>
      );
    }

    if (controllerCards.length == 0) {
      controllerCards.push(<Grid item xs={1} >
        <ArgonTypography sx={{ height: "120px" }} variant={"body2"}>No builds to compare with</ArgonTypography>
      </Grid>)
    }

    var parts = [ // HERE
      <Grid item key={"run_selector"} xs={12}>
        <Card style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 15, paddingBottom: 0 }}>
          <Grid container direction={"row"} columns={30} >
            <Grid item xs={4} >
              <Grid item container direction={"column"} spacing={2}>
                <Grid item>
                  <ArgonTypography>Comparing</ArgonTypography>
                </Grid>
                <Grid item>
                  <Card sx={{ ...cardStyle, height: "100%", transition: "all" }}>
                    <ArgonBox
                      pt={3}
                      ml={2}
                      height="100%"
                      display="flex"
                      flexDirection="column"
                      justifyContent="space-between">
                      <ArgonBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        mb={3}
                        mr={5}
                        lineHeight={1}>
                        <ArgonSelect
                          size={"large"}
                          sx={{ overflow: "hidden", backgroundColor: "red" }}
                          placeholder={"Build"}
                          menuPlacement="auto"
                          menuPosition="fixed"
                          menuPortalTarget={document.body}
                          onChange={(event) => { onComparingBuildChange(event?.value) }}
                          options={builds?.map(b => { return { value: b.name, label: "Build " + b.name } })}
                          value={{ value: appliedComparingBuild, label: "Build " + appliedComparingBuild }}
                        />
                      </ArgonBox>
                    </ArgonBox>
                  </Card>
                  {/*<CompactControllerCard
                    title={"Build " + appliedComparingBuild}
                    description={(false) ? <ArgonBadge badgeContent={"#RunTag"} variant="gradient" size="xs" color="info" container /> : ""}
                    state={true}
                    color={primary.main}
                    showSwitch={false}
                    onChange={(event) => { }}
                  />*/}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={1} width={"100%"}>
              <Grid container direction={"column"} height={"100%"} alignItems={"center"}>
                <Divider orientation="vertical" />
              </Grid>
            </Grid>
            <Grid item xs={25}>
              <Grid container direction={"column"} spacing={2}>
                <Grid item>
                  <ArgonTypography>With</ArgonTypography>
                </Grid>
                <Grid item>
                  <Grid container direction={"row"} columns={5} spacing={2}>
                    {controllerCards}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </Grid>
        </Card>
      </Grid>,
    ];

    if (chartData == null || chartsConfigs == null) {
      parts.push(<Grid container justifyContent="center" alignItems="center" key="loading">
        <Grid item>
          <ArgonBox mt={2} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <CircularProgress size={64} />
          </ArgonBox>
        </Grid>
      </Grid>)
      return parts;
    }

    if (chartData != null && chartsConfigs != null) {


      var recapI = 0;
      for (var chart of chartData.recap) {
        if (chart.data.comparing.length > 0) {
          parts.push(<Grid item key={"recap-" + recapI} xs={4} >
            <ProductivityChart
              name={chart.title}
              points={chart.data}
              comparingBuild={"Build " + appliedComparingBuild}
              selectedBuild={"Build " + selectedBuild} />
          </Grid>)
        }
        recapI++
      }

      if (availableLabels != null) {
        parts.push(<Grid item key={"label-selector"} xs={12} >
          <Card sx={{ p: "20px" }}>
            <ArgonSelect
              size={"medium"}
              sx={{ overflow: "visible", width: "100%" }}
              menuPlacement="auto"
              menuPosition="fixed"
              placeholder={"Statistic"}
              menuPortalTarget={document.body}
              onChange={(value) => { setSelectedLabel(value.value) }}
              options={availableLabels?.map(l => { return { "label": l, "value": l } }) ?? []}
              defaultValue={{ "label": "All", "value": "All" }}
            />
          </Card>
        </Grid>)
      }

      let gindex = 0
      let sortedChartsConfigs = [...chartsConfigs]
      sortedChartsConfigs.sort(function (a, b) {
        if (a.order < b.order) return -1;
        if (a.order > b.order) return 1;
        return 0;
      });
      for (var config of sortedChartsConfigs) {
        // UI charts
        if (config.type == "step-timings-table") {
          if (Object.keys(chartData.step_timings_table.comparing).length > 0) {
            parts.push(<Grid item key={"chart-" + gindex + "-0"} xs={12} >
              <Card>
                <PerfUIStepsTable
                  data={chartData.step_timings_table}
                  testInstanceName={selectedRowData.testName}
                  comparingBuild={"Build " + appliedComparingBuild}
                  selectedBuild={"Build " + selectedBuild}
                />
              </Card>
            </Grid>)
          }
        }

        if (config.type == "over-time") {
          if (config.statistic == "status-codes") {
            parts.push(<Grid item key={"chart-" + gindex + "-0"} xs={3 * config.width}>
              <Card>
                <ResponseCodePerSecond
                  selectedLabel={selectedLabel}
                  data={chartData.over_time.times_per_second.selected}
                  build={"Build " + appliedComparingBuild}
                  color={primary.main}
                />
              </Card>
            </Grid>)
            parts.push(<Grid item key={"chart-" + gindex + "-1"} xs={3 * config.width}>
              <Card>
                <ResponseCodePerSecond
                  selectedLabel={selectedLabel}
                  data={chartData.over_time.times_per_second.target}
                  build={"Build " + selectedBuild}
                  color={info.main}
                />
              </Card>
            </Grid>)

          } else {
            parts.push(<Grid item key={"chart-" + gindex} xs={3 * config.width}>
              <Card>
                <ResponseTimePctOverTime
                  selectedStatistic={config.statistic}
                  selectedLabel={selectedLabel}
                  statisticsSelectedBuild={chartData.over_time.times_per_second.selected}
                  statisticsTargetBuild={chartData.over_time.times_per_second.target}
                  comparingBuild={"Build " + appliedComparingBuild}
                  selectedBuild={"Build " + selectedBuild}
                  fillArea={config.line == "fill"}
                />
              </Card>
            </Grid>)
          }
        }
        if (config.type == "scatter") {
          parts.push(<Grid item key={"chart-" + gindex + "-0"} xs={3 * config.width}>
            <Card>
              <ResponseTimeVsRequest
                selectedLabel={selectedLabel}
                statisticsBuild={chartData.over_time.times_per_second.selected}
                build={"Build " + appliedComparingBuild}
                color={primary.main}
              />
            </Card>
          </Grid>);
          parts.push(<Grid item key={"chart-" + gindex + "-1"} xs={3 * config.width}>
            <Card>
              <ResponseTimeVsRequest
                selectedLabel={selectedLabel}
                statisticsBuild={chartData.over_time.times_per_second.target}
                build={"Build " + selectedBuild}
                color={info.main}
              />
            </Card>
          </Grid>);
        }
        if (config.type == "table") {
          parts.push(<Grid item key={"chart-" + gindex} xs={12}>
            <Card>
              <PerfRunsTable
                availableStats={availableOptions}
                data={chartData.runs_table}
              />
            </Card>
          </Grid>)
        }
        gindex += 1;
      }
    }

    if (parts.length == 1) {
      parts.push(
        <Grid item key={"error"} xs={12}>
          <Card sx={{ p: "20px" }}>
            <Box alignItems="center" justifyContent="center" display="flex" >
              <ArgonTypography>{"There seems to be no data for build"} <b>{"Build" + appliedComparingBuild}</b></ArgonTypography>
            </Box>
          </Card>
        </Grid>)
    }

    return parts;
  }

  useEffect(() => {
    setChartData(null);

    async function loadDashboard() {
      let response = await Module.getModuleByName("Performance");
      setModuleId(response.data.modules._id);
      if (configs == null) {
        await loadConfigs(response.data.modules._id);
      }
    }
    loadDashboard();
  }, [appliedFilters]);

  return (
    <DashboardLayout>
      <Header />
      <div style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh"
      }}>
        <div style={{ flex: 1 }}>
          <ArgonBox mt={5}>
            <Grid container mt={5} spacing={2}>
              <Grid item xs={12}>
                <Card>
                  {configs == null ? loading() : getFiltersHeader()}
                </Card>
              </Grid>
              <Grid item xs={12} >
                <Card>{getTableOrBreadcrumb()}</Card>
              </Grid>
              {getGraphs()}
            </Grid>
          </ArgonBox>
          <Box height={"5vh"} />
        </div>
        {/*availableLabels != null &&
          <div style={{
            position: "fixed",
            left: "17.125rem",
            right: "0px",
            bottom: 20,
          }}>
            <Card sx={{ marginLeft: "24px", marginRight: "24px", p: "20px" }}>
              <ArgonSelect
                size={"medium"}
                sx={{ overflow: "visible", width: "100%" }}
                menuPlacement="auto"
                menuPosition="fixed"
                placeholder={"Statistic"}
                menuPortalTarget={document.body}
                onChange={(value) => { setSelectedLabel(value.value) }}
                options={availableLabels?.map(l => { return { "label": l, "value": l } }) ?? []}
                defaultValue={{ "label": "All", "value": "All" }}
              />
            </Card>
          </div>*/}
      </div>
      <Footer />
    </DashboardLayout >
  );
}


export default PerformancePage;
