import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import ArgonDropzone from "components/ArgonDropzone";
import { enqueueSnackbar } from "notistack";

function UploadFileDialog({ open, onClose, onComplete, folderId }) {

  const dropzoneOptions = {
    addRemoveLinks: true,
    url: "/be/test-repository/import_file/" + folderId,
    maxFiles: 1,
    multiple: false,
    autoProcessQueue: true,
    method: "POST",
    withCredentials: true,
    headers: {
      "Authorization": "Bearer " + (localStorage.getItem("jwt-token") || sessionStorage.getItem("jwt-token"))
    },

    init: function () {

      this.on("removedfile", (file) => {
        // setDropzoneFiles((previousFiles) => previousFiles.filter((f) => f.upload.filename !== file.upload.filename))
      });

      this.on("addedfile", (file) => {
        // setIsUploading(true);
      });

      this.on("complete", (file) => {
        console.log(file)
        onComplete();
        if (file.status === 'error') {
          enqueueSnackbar('There was an error importing the XLS.', { variant: 'error' })
        } else if (file.status === 'canceled') {
          enqueueSnackbar('XLS upload cancelled.', { variant: 'warning' })
        } else {
          enqueueSnackbar('Tests imported!', { variant: 'success' })
        }

      });

      this.on("maxfilesexceeded", function (file) {
        this.removeAllFiles();
        this.addFile(file);
      });
    },
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ "& .MuiDialog-paper": { width: "100%" } }
      }
      fullWidth={"md"}
      maxWidth={"md"}
    >
      <DialogTitle textAlign="center">{"Upload XLS/XLSX/CSV"}</DialogTitle>
      <DialogContent>
        <div className="dropmehere" id="dropmehere">
          <ArgonDropzone options={dropzoneOptions} />
        </div>
      </DialogContent>
    </Dialog >
  );
}

export default UploadFileDialog;