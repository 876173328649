import { Navigate, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import cronstrue from 'cronstrue';

import Grid from "@mui/material/Grid";
import useAuth from "utils/useAuth";
import ArgonBox from "components/ArgonBox";
import Footer from "components/Footer";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import Card from "@mui/material/Card";
import Header from "components/Header";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { useMemo } from "react";
import { CardHeader, CircularProgress, Dialog, DialogContent, DialogTitle, ListItemButton, tooltipClasses } from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import { keyframes } from "@emotion/react";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Schedule } from "../../api/BackendApi/Schedule";
import { enqueueSnackbar } from "notistack";
import ArgonBadge from "components/ArgonBadge";
import PauseScheduleDialog from "./components/PauseScheduleDialog";
import { Delete } from "@mui/icons-material";


const loading = () => {
  return (<ArgonBox mt={2} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
    <CircularProgress size={64} />
  </ArgonBox>);
};

function TestScheduleMngmt() {
  const isLoggedIn = useAuth().ensureLoggedIn();
  if (!isLoggedIn) {
    return <Navigate replace to="/sign-in" />;
  }

  const [refreshCounter, setRefreshCounter] = useState(0);
  const [pausingId, setPausingId] = useState(null);
  const [pauseScheduleDialogOpen, setPauseScheduleDialogOpen] = useState(false);

  const handleClosePauseScheduleDialog = () => {
    setPauseScheduleDialogOpen(false);
    setPausingId(null);
    refresh();
  }

  const refresh = () => {
    setRefreshCounter(refreshCounter + 1);
  };

  const NoMaxWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 'none',
    },
  });

  const titleCase = (str) => {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
  }


  const typeToBadge = (type) => {
    return <ArgonBadge container color={"info"} variant="contained" size="md" badgeContent={titleCase(type.replaceAll("_", " ")).replaceAll(" Schedule", "")} />;
  };

  const handleEditSchedule = async ({ values, row, table }) => {
    await Schedule.edit(row.id, values.name, values.notes);
    enqueueSnackbar("Edit schedule success", { variant: "success" });
    table.setEditingRow(null);
    refresh();
  };

  const handlePauseSchedule = async (schedule_id) => {
    setPausingId(schedule_id);
    setPauseScheduleDialogOpen(true);
  };

  const handleUnpauseSchedule = async (schedule_id) => {
    await Schedule.pause(schedule_id, false);
    enqueueSnackbar("Schedule unpaused", { variant: "success" });
    refresh();
  };

  const handleDeleteSchedule = async (schedule_id) => {
    await Schedule.delete(schedule_id, false);
    enqueueSnackbar("Schedule unpaused", { variant: "success" });
    refresh();
  };

  const handleRemoveSchedulePauseFromTo = async (schedule_id) => {
    await Schedule.removePausePeriod(schedule_id);
    enqueueSnackbar("Schedule pause period removed", { variant: "success" });
    refresh();
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        grow: true,
        enableEditing: false,
        visibleInShowHideMenu: false
      },
      {
        accessorKey: 'name',
        header: 'Name',
        grow: true,
      },
      {
        accessorKey: 'type',
        header: 'Type',
        grow: false,
      },
      {
        accessorKey: 'notes',
        header: 'Note',
        grow: true,
      },
      {
        accessorKey: 'start',
        header: 'Start',
        size: 250,
        enableEditing: false,
      },
      {
        accessorKey: 'end',
        header: 'End',
        size: 250,
        enableEditing: false,
      },
      {
        accessorKey: 'cron',
        header: 'Cron',
        grow: true,
        enableEditing: false,
      },
      {
        accessorKey: 'pause_range',
        header: 'Pause Range',
        size: 250,
        grow: true,
        enableEditing: false,
      },
      {
        accessorKey: 'enabled',
        header: 'Enabled',
        grow: true,
        enableEditing: false,
      }
    ],
    [],
  );

  const [data, setData] = useState(null);

  const isSchedulePausedFromTo = (schedule) => {

  }

  const getSchedulePauseButton = (schedule) => {
    if (schedule.enabled === false) {
      return <Tooltip title="Play" onClick={() => handleUnpauseSchedule(schedule._id)}>
        <IconButton color="success">
          <PlayCircleIcon />
        </IconButton>
      </Tooltip>;
    }
    return <Tooltip title="Pause" onClick={() => handlePauseSchedule(schedule._id)}>
      <IconButton color="info">
        <PauseCircleIcon />
      </IconButton>
    </Tooltip>;
  }

  const table = useMaterialReactTable({
    columns: columns,
    data: data ?? [], //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    initialState: { density: 'compact', pagination: { pageSize: 30 }, columnVisibility: { id: false } },
    enableColumnResizing: true,
    columnResizeMode: 'onEnd',
    enableDensityToggle: false,
    columnFilterDisplayMode: 'popover',
    enableEditing: true,
    editDisplayMode: 'row',
    onEditingRowSave: handleEditSchedule,
    getRowId: (row) => row.id,  //custom row id
    positionActionsColumn: 'last',
    muiPaginationProps: {
      rowsPerPageOptions: [30, 50, 100],
    },
    displayColumnDefOptions: {
      'mrt-row-actions': {
        size: 150, //if using layoutMode that is not 'semantic', the columns will not auto-size, so you need to set the size manually
        grow: false,
      },
    },
    muiTableHeadProps: {
      sx: {
        padding: '0 !important',
      },
    },
    muiEditTextFieldProps: {
      inputProps: {
        sx: {
          width: '100% !important',
          height: '100% !important',
        },
      },
    },
    muiTableBodyCellProps: {
      sx: {
        fontSize: "0.8rem",
        justifyContent: 'center',
      },
    },
    muiTableHeadCellProps: {
      sx: {
        fontSize: "0.8rem",
        '& .Mui-TableHeadCell-Content': {
          justifyContent: 'center',
        },
      },
    },
    muiTablePaperProps: ({ table }) => ({
      //not sx
      style: {
        zIndex: table.getState().isFullScreen ? 10000 : undefined,
      },
    }),
    muiExpandButtonProps: ({ row, table }) => ({
      onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }), //only 1 detail panel open at a time
      sx: {
        transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
        transition: 'transform 0.2s',
      },
    }),
    renderDetailPanel: ({ row }) => (
      <ArgonBox
        sx={{
          width: '1400px',
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Card>
              <CardHeader
                title="Test Case"
                sx={{ textAlign: 'center' }}
              />
              <CardContent>
                <List sx={{ width: '100%' }}>
                  {row.original.tests?.map((test) => {
                    return (
                      <ListItem
                        key={test.test_instance_id.id}
                        disablePadding
                      >
                        <ListItemButton role={undefined}
                          //onClick={handleToggle(value)}
                          dense>
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              checked={test.enabled === undefined ? true : test.enabled}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ 'aria-labelledby': test.id }}
                              onChange={() => {
                                Schedule.editTest(row.original.id, test.test_instance_id.id, test.enabled === undefined ? false : !test.enabled).then(
                                  () => {
                                    enqueueSnackbar("Enable/Disable test success!", { variant: "success" });
                                    refresh();
                                  }
                                )
                              }}
                            />
                          </ListItemIcon>
                          <ListItemText id={test.test_instance_id.id} primary={test.test_instance?.test?.name ?? "Missing instance"} />
                        </ListItemButton>
                      </ListItem>
                    )
                  })}
                </List>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card>
              <CardHeader
                title="Other Info"
                sx={{ textAlign: 'center' }}
              />
              <CardContent>

              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </ArgonBox>
    ),
    renderRowActions: ({ row, table }) => {
      console.log(row);
      return (
        <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '0.5rem' }}>
          <Tooltip title="Edit">
            <IconButton >
              <EditIcon onClick={() => table.setEditingRow(row)} />
            </IconButton>
          </Tooltip>
          {getSchedulePauseButton(row.original.data)}
          <Tooltip title="Delete">
            <IconButton color="error" onClick={() => handleDeleteSchedule(row.id)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      );
    }
  });

  const doWork = async () => {
    let schedulesResponse = await Schedule.getAll();
    let schedules = [];
    if (schedulesResponse.status === 200) {
      schedules.push(
        ...schedulesResponse.data.schedules.map((schedule) => {

          const shapeStyles = { bgcolor: 'primary.main', width: 25, height: 25 };
          const shapeCircleStyles = { borderRadius: '50%' };
          const blink = keyframes` from { opacity: 0; } to { opacity: 1; }`;

          let cron;
          if (schedule.options.cron != null) {
            let cronString = cronstrue.toString(schedule.options.cron)

            cron = <NoMaxWidthTooltip title={cronString} placement="top">
              <ArgonBadge container color={"primary"} variant="contained" size="md" badgeContent={schedule.options.cron} />
              
            </NoMaxWidthTooltip>
          } else {
            cron = <ArgonBadge container color={"primary"} variant="contained" size="md" badgeContent={"One shot"} />; 
          }

          let enabled = null

          if (schedule.enabled) {
            enabled = <NoMaxWidthTooltip title={"Scheduled"} placement="top">
              <Box component="span" sx={{ ...shapeStyles, ...shapeCircleStyles, backgroundColor: '#1aae6f' }} />
            </NoMaxWidthTooltip>
          } else {
            let title = "Paused"
            if (schedule.pause_from !== null && schedule.pause_to !== null) {
              title = "On pause from " + schedule.pause_from + " to " + schedule.pause_to + " - " + title
            }
            enabled = <NoMaxWidthTooltip title={title} placement="top">
              <Box component="span" sx={{ ...shapeStyles, ...shapeCircleStyles, backgroundColor: '#e86d26', animation: `${blink} 1.5s linear infinite` }} />
            </NoMaxWidthTooltip>
          }

          let pause_range_children = [];
          if (schedule.pause_from != null) {
            pause_range_children = [
              <Box key={0}>{schedule.pause_from}</Box>,
              <br key={1}></br>,
              <Box key={2}>{schedule.pause_to}</Box>,
              <br key={3}></br>,
              <IconButton key={4} aria-label="edit" color="error" onClick={() => handleRemoveSchedulePauseFromTo(schedule._id)}>
                <Delete />
              </IconButton>
            ];
          }

          return {
            id: schedule._id,
            type: typeToBadge(schedule.type),
            name: schedule.name,
            notes: schedule.notes,
            start: schedule.start,
            end: schedule.end ? schedule.end : "Never",
            cron: cron,
            enabled: enabled,
            tests: schedule.tests,
            mail_me: schedule.options.mail_me,
            pause_range: pause_range_children,
            mail_mailing_list: schedule.options.mail_mailing_list,
            data: schedule
          }
        })
      )
    }
    setData(schedules);
  };

  useEffect(() => {
    doWork();
  }, [refreshCounter]);

  let body;
  if (data == null) {
    body = loading();
  } else {
    body = <MaterialReactTable table={table} />
  }

  return (
    <DashboardLayout>
      <Header />
      <PauseScheduleDialog
        open={pauseScheduleDialogOpen}
        onClose={handleClosePauseScheduleDialog}
        scheduleId={pausingId}
      />
      <ArgonBox mt={5} mb={3}>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={11}  >
            <Card sx={{ minHeight: "calc(100vh - 30vh)", display: "flex" }}>
              <ArgonBox pt={2} pl={2} pr={2}>
                {body}
              </ArgonBox>
            </Card>
          </Grid>
        </Grid>
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  );
}

export default TestScheduleMngmt;
