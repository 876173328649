import { useEffect, useState } from "react";

// @mui material components
import styled from "@emotion/styled";
import { Box, Tooltip, tooltipClasses } from "@mui/material";
import ArgonSelect from "components/ArgonSelect";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";

function PerfRunsTable({ availableStats, data }) {

    const [selectedStat, setSelectedStat] = useState("meanResTime");

    const [columns, setColumns] = useState([{
        accessorKey: 'name',
        header: 'Name',
        grow: true
    }]);

    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        var selectedData = data.selected.data
        var comparingData = data.comparing.data

        var selectedOrderedRunIds = data.selected.ordered_run_ids

        let columns = [{
            accessorKey: 'label',
            header: 'Label',
            grow: true,
        }]

        let rows = []

        for (var label of Object.keys(selectedData)) {
            let rowData = {
                "label": label
            }
            for (var run of selectedOrderedRunIds) {
                console.log(run)
                console.log(selectedData[label])
                rowData[run] = selectedData[label][run]["avg"]
            }
            rows.push(rowData)
        }
        var i = 1
        for (let run of selectedOrderedRunIds) {
            columns.push({
                accessorKey: run,
                header: 'Run ' + i,
                grow: true,
                muiTableHeadCellProps: {
                    align: 'center',
                },
                muiTableBodyCellProps: {
                    align: 'center',
                },
                muiTableFooterCellProps: {
                    align: 'center',
                }
            })
            i++;
        }

        setColumns(columns)
        setTableData(rows)
    }, [data, selectedStat])

    const onTestSelectionTableOptionChange = (option) => {
        setSelectedStat(availableOptionsMappings[option.value]);
    }

    const table = useMaterialReactTable({
        columns: columns,
        data: tableData, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
        initialState: { columnVisibility: { id: false }, density: 'compact', pagination: { pageSize: 30 } },
        enableColumnResizing: true,
        columnResizeMode: 'onEnd',
        enableDensityToggle: false,
        columnFilterDisplayMode: 'popover',
        renderTopToolbarCustomActions: ({ table }) => (
            <Box sx={{ display: 'flex', gap: '1rem', p: '4px', overflow: "visible" }}>
                <ArgonSelect
                    size={"small"}
                    sx={{ overflow: "visible" }}
                    placeholder={"Width"}
                    menuPortalTarget={document.body}
                    onChange={onTestSelectionTableOptionChange}
                    options={availableStats}
                    defaultValue={availableStats[0]}
                />
            </Box>
        ),
        muiPaginationProps: {
            rowsPerPageOptions: [30, 50, 100],
        },
        muiTableHeadProps: {
            sx: {
                padding: '0 !important',
            },
        },
        muiEditTextFieldProps: {
            inputProps: {
                sx: {
                    width: '100% !important',
                    height: '100% !important',
                },
            },
        },
        muiTableBodyCellProps: {
            sx: {
                fontSize: "0.8rem",
            },
        },
        muiTableHeadCellProps: {
            sx: {
                fontSize: "0.8rem",
                '& .Mui-TableHeadCell-Content': {
                    justifyContent: 'center',
                },
            },
        },
        muiTableBodyRowProps: ({ row }) => ({
            onClick: (event) => {
                onSelectedRow(row.original);
            },
            sx: {
                cursor: 'pointer', //you might want to change the cursor too when adding an onClick
            },
        }),
        muiTablePaperProps: ({ table }) => ({
            //not sx
            style: {
                zIndex: table.getState().isFullScreen ? 10000 : undefined,
            },
        }),
    });

    return (
        <MaterialReactTable table={table} />
    );
}

export default PerfRunsTable;