import apiClient from "api/BackendApi";


const PerfChartsAPI = {
  getConfigs: (platform) => apiClient.get("/be/perf/config/list?platform=" + platform),
  addConfig: (body, platform) => apiClient.post("/be/perf/config/add?platform=" + platform, body),
  remove: (id, platform) => apiClient.delete("/be/perf/config/remove?id=" + id + "&platform=" + platform),
  getRunsTable: (configurationFilters, platform) => apiClient.post(
    "/be/perf/tests_runs_table?platform=" + platform,
    { "filters": configurationFilters }
  ),
  getBuildsTable: (configurationFilters, platform) => apiClient.post(
    "/be/perf/builds_table?platform=" + platform,
    { "filters": configurationFilters }
  ),
  getBuilds: (platform) => apiClient.get("/be/perf/builds?platform=" + platform),
  loadComparisonCharts: (data, platform) => apiClient.post("/be/perf/build_comparison?platform=" + platform, data),
  setChartsConfigsOrder: (orders, platform) => apiClient.post("/be/perf/config/order?platform=" + platform, orders),
}

export {
  PerfChartsAPI
};
