import { Box, Card } from "@mui/material";
import ArgonSelect from "components/ArgonSelect";
import ReactEcharts from "echarts-for-react";
import { useCallback, useEffect, useMemo, useState } from "react";

import colors from "assets/theme/base/colors";

const { white, info, primary, secondary, dark } = colors;

function ResponseTimeVsRequest({ selectedLabel, statisticsBuild, build, color, height = '500px', showTitle = true, card = true, compact = false, download = true }) {

    const [_selectedLabel, setSelectedLabel] = useState(null)

    const [_statisticsBuild, setStatisticsBuild] = useState(null)

    const [option, setOption] = useState(null)

    const [_build, setBuild] = useState(null)

    useEffect(() => {
        setBuild(build)
    }, [build])

    useEffect(() => {
        setSelectedLabel(selectedLabel)
    }, [selectedLabel])

    useEffect(() => {
        setStatisticsBuild(statisticsBuild)
    }, [statisticsBuild])

    useEffect(() => {
        console.log("Building!")
        if ([_selectedLabel,
            _statisticsBuild,
            _build].includes(null)) return;

        const okData = _statisticsBuild[_selectedLabel]["resp_time_vs_throughput"]["ok"].map(v => ({ value: v, category: 'Success' }));
        const nokData = _statisticsBuild[_selectedLabel]["resp_time_vs_throughput"]["nok"].map(v => ({ value: v, category: 'Failure' }));

        // Step 2: Merge and sort data based on the X-axis value (throughput)
        const mergedData = [...okData, ...nokData].sort((a, b) => a.value[0] - b.value[0]);

        setOption({
            title: showTitle ? {
                text: 'Response Time vs Throughput ({a|' + build + '})',
                left: 'center',
                top: '10px',
                textStyle: {
                    rich: {
                        a: {
                            fontStyle: 'normal',
                            fontWeight: 'bolder',
                            fontFamily: 'sans-serif',
                            fontSize: 18,
                            color: color
                        }
                    }
                }
            } : {},
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'line',
                    label: {
                        show: true,
                        backgroundColor: '#6a7985'
                    }
                },
                formatter: function (params) {

                    if (params.length == 0) return;

                    let tooltipContent = ``;

                    let addedValues = new Map()

                    for (let item of params) {
                        if (!addedValues.has(item.seriesName)) {
                            console.log(addedValues)
                            addedValues.set(item.seriesName, [])
                        }

                        if (addedValues.get(item.seriesName).includes(item.value[1])) continue;

                        console.log(item)

                        tooltipContent += `<span style="display: inline-block; width: 10px; height: 10px; background-color: ${item.color}; margin-right: 5px;"></span>${item.color == '#77DD77' ? 'Success' : 'Failure'}</span>: ${item.value[1]}ms<br>`;

                        addedValues.get(item.seriesName).push(item.value[1])
                    }

                    tooltipContent += `<br>At <span style="font-weight: bold; margin-bottom: 10px;"> ${params[0].value[0]} </span>req/s`

                    return tooltipContent;
                }
            },
            toolbox: {
                feature: download == true ? {
                    saveAsImage: {}
                } : {}
            },
            grid: {
                top: compact ? '20px' : '45px',
                left: compact ? '25px' : '60px',
                right: compact ? '25px' : '40px',
                bottom: compact ? '20px' : '60px',
                containLabel: false
            },
            xAxis: [
                {
                    type: 'category',
                    name: 'Number of requests per second',
                    nameLocation: 'middle',
                    nameGap: 35,
                    // data: _statisticsBuild[_selectedLabel]["resp_time_vs_throughput"]["throughputs"]
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    name: "Median response time (ms)",
                    nameLocation: 'middle',
                    nameGap: 35
                }
            ],
            series: [{
                name: 'Response Time',
                symbolSize: 8,

                type: 'scatter',
                data: mergedData.map(d => d.value), // Extract only the value arrays
                itemStyle: {
                    color: function (params) {
                        return mergedData[params.dataIndex].category === 'Success' ? '#77DD77' : '#FF6961';
                    }
                }

            }]
        });
    }, [_selectedLabel,
        _statisticsBuild,
        _build
    ]);

    return card
        ? <Card>
            {option && <ReactEcharts option={option} style={{ height: height }} />}
        </Card>
        : <>{option && <ReactEcharts option={option} style={{ height: height }} />}</>;
}

export default ResponseTimeVsRequest;