import { useEffect, useState } from "react";

// @mui material components
import styled from "@emotion/styled";
import { Box, FormControlLabel, Icon, Switch, Tooltip, tooltipClasses } from "@mui/material";
import typography from "assets/theme/base/typography";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";

const NoMaxWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 'none',
    },
});

const UnitSwitch = ({ handleChange, isPercentage }) => {
    return (
        <FormControlLabel
            control={<Switch checked={isPercentage} onChange={(event) => handleChange(event.target.checked)} sx={{
                marginTop: 1,
            }} />}
            label={<NoMaxWidthTooltip title={"Show time difference between builds in " + (isPercentage ? "percentage" : "milliseconds")} placement="top">
                {isPercentage ? "Percentage" : "Milliseconds"}
            </NoMaxWidthTooltip>}

        />
    );
};


function PerfUIStepsTable({ data, testInstanceName, comparingBuild, selectedBuild }) {

    const [isPercentage, setIsPercentage] = useState(false);

    const { size } = typography;

    const [columns, setColumns] = useState([{
        accessorKey: 'name',
        header: 'Name',
        grow: true
    }]);

    const [tableData, setTableData] = useState([]);

    const percentageDifference = (reference, value) => {
        if (reference === 0) {
            throw new Error("Reference value cannot be zero.");
        }
        return (((value - reference) / reference) * 100).toFixed(1);
    }

    const millisecondDifference = (reference, value) => {
        return (value - reference).toFixed(1);
    }

    const arrayAverage = (arr) => {
        if (arr.length === 0) return 0; // Handle empty array case
        return (arr.reduce((sum, num) => sum + num, 0) / arr.length).toFixed(2);
    }

    const createCellContent = (valueComparing, valueSelected) => {
        let tooltip = <div>{comparingBuild + " "}<b>{valueComparing}ms</b> <br /> {selectedBuild} <b>{valueSelected}ms</b> </div>

        let cellContent = <></>
        if (valueSelected != null) {
            let icon = "trending_flat"
            let iconColor = "primary"

            if (valueComparing > valueSelected) {
                icon = "trending_down"
                iconColor = "error"
            }
            if (valueComparing < valueSelected) {
                icon = "trending_up"
                iconColor = "success"
            }
            cellContent = <ArgonBox
                fontSize={size.lg}
                color={iconColor}
                mb={0.3}
                mr={0.5}
                lineHeight={0}
                display="flex"
                alignItems="center"
            >
                <Icon sx={{ fontWeight: "bold", mr: 0.5 }}>{icon}</Icon>
                <ArgonTypography fontSize="12px" color={iconColor}>
                    {isPercentage ? percentageDifference(valueComparing, valueSelected) + "%" : millisecondDifference(valueComparing, valueSelected) + "ms"}
                </ArgonTypography>
            </ArgonBox>

        }

        return <ArgonTypography variant="body2">
            <NoMaxWidthTooltip title={tooltip} placement="top">
                <b>{valueComparing}</b> ms {cellContent}
            </NoMaxWidthTooltip>
        </ArgonTypography>
    }

    useEffect(() => {
        var comparingData = data.comparing
        var selectedData = data.selected

        let columns = [{
            accessorKey: 'test',
            header: 'Test',
            grow: true,
        }]

        let rows = []
        let labels = Object.keys(comparingData)

        var stepNumber = 1
        for (var label of labels) {
            columns.push({
                accessorKey: label.replace(".", "_"),
                header: <NoMaxWidthTooltip title={label} placement="top">
                    {"Step #" + stepNumber}
                </NoMaxWidthTooltip>,
                grow: true,
                muiTableHeadCellProps: {
                    align: 'center',
                },
                muiTableBodyCellProps: {
                    align: 'center',
                },
                muiTableFooterCellProps: {
                    align: 'center',
                }
            })
            stepNumber++
        }

        var runCountComparing = Object.values(comparingData)[0].length
        var runCountSelected = Object.values(selectedData)[0]?.length ?? 0;

        let total = {}

        for (var runIndex = 0; runIndex < runCountComparing; runIndex++) {
            let rowData = {
                "test": <ArgonTypography variant="body2">{testInstanceName}</ArgonTypography>
            }

            for (var label of labels) {
                let valueComparing = comparingData[label][runIndex]

                if (!(label in total)) {
                    total[label] = { comparing: [], selected: [] }
                }

                total[label].comparing.push(valueComparing)

                let valueSelected = null;
                if (runIndex < runCountSelected) {
                    valueSelected = selectedData[label]?.[runIndex] ?? null
                    total[label].selected.push(valueSelected)
                }

                rowData[label.replace(".", "_")] = createCellContent(valueComparing, valueSelected)
            }

            rows.push(rowData)
        }

        let recapRow = {
            "isRecap": true,
            "test": <ArgonTypography variant="body2"><b>{"Total (Average)"}</b></ArgonTypography>,
        }

        for (let [label, values] of Object.entries(total)) {
            recapRow[label] = createCellContent(arrayAverage(values.comparing), values.selected.length == 0 ? null : arrayAverage(values.selected))
        }
        rows.push(recapRow)

        setColumns(columns)
        setTableData(rows)
    }, [data, testInstanceName, isPercentage])

    const table = useMaterialReactTable({
        columns: columns,
        data: tableData, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
        initialState: { columnVisibility: { id: false }, density: 'compact', pagination: { pageSize: 30 } },
        enableColumnResizing: true,
        columnResizeMode: 'onEnd',
        enableDensityToggle: false,
        columnFilterDisplayMode: 'popover',
        renderTopToolbarCustomActions: ({ table }) => (Object.values(data.selected)[0]?.length ?? 0) == 0 ? <></>: <Box ml={2}><UnitSwitch handleChange={setIsPercentage} isPercentage={isPercentage} /></Box>,
        muiPaginationProps: {
            rowsPerPageOptions: [30, 50, 100],
        },
        muiTableHeadProps: {
            sx: {
                padding: '0 !important',
            },
        },
        muiEditTextFieldProps: {
            inputProps: {
                sx: {
                    width: '100% !important',
                    height: '100% !important',
                },
            },
        },
        muiTableBodyCellProps: {
            sx: {
                fontSize: "0.8rem",
            },
        },
        muiTableHeadCellProps: {
            sx: {
                fontSize: "0.8rem",
                '& .Mui-TableHeadCell-Content': {
                    justifyContent: 'center',
                },
            },
        },
        muiTableBodyRowProps: ({ row }) => ({
            sx: {
                backgroundColor: row.original.isRecap === true ? "#f1f8fe" : "#ffffff",
                cursor: 'pointer', //you might want to change the cursor too when adding an onClick
            },
        }),
        muiTablePaperProps: ({ table }) => ({
            //not sx
            style: {
                zIndex: table.getState().isFullScreen ? 10000 : undefined,
            },
        }),
    });

    return (
        <MaterialReactTable table={table} />
    );
}

export default PerfUIStepsTable;